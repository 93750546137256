import axios from "../interceptors/api-client";
import { useDispatch } from "react-redux"
import Config from "../models/config.model";
import { pmsEndPoint } from '../../config/config.js'
import { getDeviceToken } from "../interceptors/api-client";
import publicIp from "react-public-ip"

export class SettingsService {
    dispatch = useDispatch()
    static settings: Config
    static clientIP: string

    async authenticate(vendorName) {
      const urlParams = new URLSearchParams(window.location.search);

      // Get a specific query parameter, for example 'param'
      const deviceId = urlParams.get('device');
      
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: pmsEndPoint + '/vendors/auth/' + vendorName.toLowerCase(),
          headers:{
            "duuid": deviceId ?? getDeviceToken()
          }
        };
        try {
          const response = await axios.request(config)
          localStorage.setItem(vendorName, JSON.stringify(response.data));
          await this.getClientIP()
        } catch (error) {
          //@ts-ignore
          if(error.response?.status === 404){
            window.location.replace(`${window.location.protocol}//${window.location.host}/visualizer`);
          }
        }
    }

    static getCompanyConfig = () => {
      return this.settings
    }

    async getCompanyTheme(vendorName) {
      await this.authenticate(vendorName)
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: pmsEndPoint + '/config',
          vendorName: vendorName
        };

        const response = await axios.request(config)
        const companyConfig: Config = response.data
        companyConfig.alias = vendorName
        SettingsService.settings = companyConfig
        this.setVendorAreas()
        return companyConfig
    }

    async setVendorAreas() {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: pmsEndPoint + '/vendors/areas'
        };

        const response = await axios.request(config)
        SettingsService.settings.areas = response.data
    }

    async getClientIP () {
      let ip = await publicIp.v4() || "";
      ip = ip === "" ? await publicIp.v6() || "" : ip;
      SettingsService.clientIP = ip
    };

    async getSharingData(sharingId) {
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: pmsEndPoint + '/sharing/' + sharingId
        };

        const response = await axios.request(config)
        return response.data
    }

    static vendorHasRugs = () => (SettingsService.settings.areas?.find(area => area.toLowerCase() === "interactive_floor"))
    static vendorHasFloor = () => (SettingsService.settings.areas?.find(area => area.toLowerCase() === "floor"))
    static vendorHasWalls = () => (SettingsService.settings.areas?.find(area => area.toLowerCase() === "walls"))

}