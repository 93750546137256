import { CheckCircleOutline } from "@mui/icons-material";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export function ShareSpaceModal({isOpen, handleClose, URL}) {
    const {t} = useTranslation()
    const [copyDone, setCopyDone] = useState(false)

    const copyToClipBoard = async() => {
      navigator.clipboard.writeText(URL).then(() => {
        setCopyDone(true)
      })
    }

    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          maxWidth={'lg'}
          sx={{zIndex: 900000, borderRadius: "20px"}}
        >
          <DialogTitle id="alert-dialog-title" padding={4}>
            Share your results with your friends
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {URL}
            </DialogContentText>
            {copyDone && <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success" sx={{marginTop: 1}}>
              Copied!
            </Alert>}
          </DialogContent>
          <DialogActions>
          
            <Button onClick={copyToClipBoard} variant="outlined" sx={{textTransform: "none"}}>Copy link</Button>
            
          </DialogActions>
          
        </Dialog>
      </React.Fragment>
    );
  }
