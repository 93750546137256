
import { useContext, useEffect, useState } from "react";
import { VendorThemeContext, VendorThemeProvider } from "../providers/VendorThemeProvider";
import { SettingsService } from "../common/services/settings.service";
import { setSurfaces } from "../redux/surfaces/surfacesActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedSpace } from "../redux/space/spaceActions";

export function SpaceShare() {
    const {vendorAlias} = useContext(VendorThemeContext);
    const [settingsService] = useState<SettingsService>(new SettingsService())
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const getSharingData = async () => {
        try {
            const sharingData = await settingsService.getSharingData(window.location.href.split("/")[5])
            dispatch(setSurfaces(sharingData.visualizeData, sharingData.space.id))
            dispatch(setSelectedSpace(sharingData.space))
            navigate(`/visualizer/${vendorAlias}/playground`)
        } catch (error) {
            navigate(`/visualizer/playground`)
        }
    }

    useEffect(() => {
        getSharingData()
    }, [])

    return (
            <></>
    );
}