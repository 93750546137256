import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SpacePicker } from './pages/space-picker.page';
import { SpaceVisualizer } from './pages/space-visualizer.page';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './all.scss'
import './App.scss'
import { IframeProvider } from './providers/IFrameContext';
import { ARVisualizer } from './pages/ar-visualizer.page';
import { UploadSpace } from './pages/upload-space.page';
import { SpaceShare } from './pages/space-share.page';
import { VendorThemeProvider } from './providers/VendorThemeProvider';
/** Import main fonts */
//import './common/fonts/fonts.js'

function App() {
    const { i18n } = useTranslation();

    useEffect(() => {
        // Prevent pinch-to-zoom globally
        const handleGlobalTouchMove = (event) => {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        };
    
        document.addEventListener('touchmove', handleGlobalTouchMove, { passive: false });
    
        // Re-enable zoom for a specific div by its ID
        const allowZoomOnDiv = (event) => {
          const zoomableDiv = document.getElementById('TransformWrapper'); // Replace 'zoomableDivId' with the actual ID
    
          if (zoomableDiv && zoomableDiv.contains(event.target)) {
            // Allow zoom on this div (do nothing)
            alert("HERE")
          } else {
            // Prevent zoom elsewhere
            if (event.touches.length > 1) {
              event.preventDefault();
            }
          }
        };
    
        document.addEventListener('touchmove', allowZoomOnDiv, { passive: false });
    
        // Clean up event listeners on component unmount
        return () => {
          document.removeEventListener('touchmove', handleGlobalTouchMove);
          document.removeEventListener('touchmove', allowZoomOnDiv);
        };
      }, []);

    return (
        <div dir={i18n?.language === "ar" ? "rtl": "ltr"}>
            <IframeProvider>
                <BrowserRouter>
                    <div className="App">
                        <Routes>
                            <Route path="/visualizer" element={<VendorThemeProvider><SpacePicker /></VendorThemeProvider>} />
                            <Route path="/visualizer/:vendorAlias" element={<VendorThemeProvider><SpacePicker /></VendorThemeProvider>} />
                            <Route path="/visualizer/:vendorAlias/playground" element={<VendorThemeProvider><SpaceVisualizer /></VendorThemeProvider>} />
                            <Route path="/visualizer/playground" element={<VendorThemeProvider><SpaceVisualizer /></VendorThemeProvider>} />
                            <Route path="/ar/:vendorAlias/products/:productId" element={<ARVisualizer />} />
                            <Route path="/space-uploader/:vendorAlias" element={<VendorThemeProvider><UploadSpace /></VendorThemeProvider>} />
                            <Route path="/share/:vendorAlias/:sharingId" element={<VendorThemeProvider><SpaceShare /></VendorThemeProvider>} />
                        </Routes>
                    </div>
                </BrowserRouter>
            </IframeProvider>
        </div>
    );
}
export default App;